import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Carousel, { Modal, ModalGateway } from 'react-images'
import ContactForm from '../ContactForm'

const ArticleWrapper = styled.div`
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
    margin-bottom: 45px;
    padding: 25px 40px;
    color: #34424a;

    h1.main-title {
        font-size: 32px;
        line-height: 1.2em;
        margin: 25px 0 35px;
        padding-bottom: 15px;
        border-bottom: 4px solid #f1e663;
    }

    .content-container {
        max-width: 950px;
    }

    p {
        margin-bottom: 45px;
    }

    @media screen and (max-width: 650px) {
        padding: 25px;
    }
`

const MetaInfo = styled.div`
    font-size: 13px;
    margin-bottom: 7px;

    ul {
        list-style: none;
        margin: 0 0 0 7px;
        padding: 0;
        display: inline-block;

        li {
            margin: 0 7px 0 0;
            padding: 0;
            list-style: none;
            color: #bdc9d1;
            display: inline-block;
        }
    }
`

const Banner = styled.div`
    max-height: 300px;
    margin-bottom: 45px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom right, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,1) 100%);
        opacity: .75;
        z-index: 2;
    }
    
    img {
        width: 100%;
    }
`

const ImageGallery = styled.div`
    width: 100%;
    margin: 25px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
`

const GalleryItem = styled.div`
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 300px;
    position: relative;
    cursor: zoom-in;
    border: 1px solid #c5c5c5;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.24),
          0 5px 10px 0 rgba(0,0,0,0.12);
    transition: all .5s;

    @media screen and (min-width: 600px) {
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 7px 15px 0 rgba(0,0,0,0.18),
            0 3px 8px 0 rgba(0,0,0,0.08);
        }
    }

    img {
        width: 100%;
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5%;
        right: -5%;
        margin: auto;
        object-fit: cover;
    }
`

const DisqusWrapper = styled.div`
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
  margin-bottom: 45px;
  padding: 25px 40px;
  color: #34424a;

  @media screen and (max-width: 650px) {
    padding: 25px;
  }
`

const ContactFormWrapper = styled.div`
    background: linear-gradient(to right bottom, rgb(29, 28, 35) 35%, rgb(76, 60, 113) 100%);
    border-radius: 7px;
    box-shadow: 2px 2px 25px 0 rgba(0,0,0,.16);
    margin-bottom: 45px;
    padding: 55px 95px;
    color: #34424a;

    .contact-head {
        margin: 0 auto 35px;
        color: #fff;
        text-align: center;

        p {
            margin: 0 auto;
            max-width: 750px;
        }
    }

    @media screen and (max-width: 900px) {
        padding: 45px 65px;
    }

    @media screen and (max-width: 650px) {
        padding: 45px 25px;
    }
`

const Article = ({ projectImages, image, title, date, tags, content, slug }) => {
    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const openLightbox = useCallback((event, { photo, index }) => {
        console.log(photo)
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])
    
    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }


    return (<>
        <ArticleWrapper>
            <MetaInfo>
                {date} |
                <ul>
                    {tags.map((tag) => (<li>{tag}</li>))}
                </ul>
            </MetaInfo>
            {image ? <Banner><img srcSet={image} alt={title} /></Banner> : ''}
            <div className="content-container" dangerouslySetInnerHTML={({__html:content})} />
            {projectImages.length > 1 ? (<>
                <h2>Project Images</h2>
                <ImageGallery>
                    {projectImages.map((img, index) =>  {
                        const currIndex = index
                        return (<GalleryItem key={currIndex} onClick={(e) => {
                                openLightbox(e, {img, index})
                            }}>
                                <img srcSet={img.sizes.srcSet} alt={img.title} />
                            </GalleryItem>
                        )}
                    )}
                </ImageGallery>
            </>) : ''}
        </ArticleWrapper>

        <ContactFormWrapper>
            <div className="contact-head">
                <h2>Are you looking for some help with your project?</h2>
                <p>Feel free to reach out to me. I love working on awesome projects, let's hear about yours.</p>
            </div>
            <ContactForm serviceData={[]} />
        </ContactFormWrapper>

        {projectImages.length > 1 ? (
        <ModalGateway>
            {viewerIsOpen ? (
            <Modal style={{ position: 'absolute' }} onClose={closeLightbox}>
                <Carousel
                currentIndex={currentImage}
                views={projectImages.map(img => ({
                    src: img.sizes.src,
                    srcset: img.sizes.srcSet,
                    caption: img.title
                }))}
                />
            </Modal>
            ) : null}
        </ModalGateway>) : '' }
    </>)
}

Article.propTypes = {
    slug: PropTypes.string.isRequired,
    projectImages: PropTypes.array,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    content: PropTypes.object.isRequired
}

Article.defaultProps = {
    projectImages: [],
    image: ''
}

export default Article
