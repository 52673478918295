import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../../layouts/Layout'
import NavBar from '../NavBar'
import Overlay from '../Overlay'
import ProjectArticle from './ProjectArticle'
import Footer from '../../sections/Footer'

const Wrapper = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0;
    background: url('/images/wood-bg.jpg');
    box-shadow: 0 12px 30px 0 rgba(0,0,0,0.11),
    0 5px 15px 0 rgba(0,0,0,0.08);
  `

  const Hero = styled.div`
    background-image: url('/images/banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    color: #fff;
    padding: 20px 15px 200px;

    h1 {
      text-align: center;
      font-size: 47px;
      text-transform: uppercase;
      margin: 40px 0 55px;
      line-height: 1em;
      position: relative;
      z-index: 2222;

      &:after {
        content: "";
        display: block;
        background-image: -webkit-linear-gradient(180deg,#61a9e2,#815cec 50%,#f1e763);
        height: 4px;
        width: 140px;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 20px 15px 145px;
    }
  `
  
  const SingleContainer = styled.section`
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 55px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 25px;
    z-index: 2222;

    @media screen and (max-width: 650px) {
      padding: 0 15px;
    }
  `

  const ArticleWrapper = styled.div`
    position: relative;
    display: inline-block;
    top: -150px;
    margin-bottom: -120px;
    grid-column: span 3;

    @media screen and (max-width: 650px) {
      grid-column: span 2;
    }
  `

const ProjectSingle = ({ data }) => {
  const post = data.contentfulProjects

  return (
    <Wrapper>
      <Layout siteTitle={`${post.title} :: Raymond Ware`} siteDescription={post.description} />
      <Hero>    
        <Overlay bg="linear-gradient(to bottom right, rgba(61, 51, 96,1) 10%,rgba(85, 204, 162,0) 100%);" opacityVal=".98" display="none" mediaDisplay="2460px" />
          <NavBar />
      </Hero>
      <SingleContainer>
        <ArticleWrapper>
          <ProjectArticle slug={post.slug} projectImages={post.projectImages} image={post.thumbnailImage.sizes.srcSet} title={post.title} date={post.createdAt} tags={post.technologyUsed} content={post.projectContent.childMarkdownRemark.html} />
        </ArticleWrapper>
      </SingleContainer>
      <Footer />
    </Wrapper>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProjects(slug: {eq: $slug}) {
      title
      description
      id
      slug
      thumbnailImage {
        sizes {
          srcSet
        }
      }
      technologyUsed
      projectContent {
        childMarkdownRemark {
          html
        }
      }
      projectImages {
        sizes(maxWidth: 1500) {
          srcSet
          src
        }
        title
      }
      createdAt(fromNow: true)
    }
  }
`

ProjectSingle.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProjectSingle
